<template>
  <div id="alarm">
    <!-- 报警信息 -->
    <!-- 左侧树 -->
    <app-tree @select="onSelect" v-bind:TYPE="`${$route.params.TYPE}`"></app-tree>
    <!-- 内容展示区 -->
    <div class="content">
      <!-- 顶部的title -->
      <h3 class="title">
        <span v-if="$store.state.parentData.name"
          >{{ $store.state.parentData.name }}-</span
        >
        <span v-if="$store.state.selectedData.name">{{
          $store.state.selectedData.name
        }}</span>
      </h3>
      <!-- 查询条件 -->
      <div class="search-criteria">
        <a-space :size="size">
          <div class="large-title">时效性:</div>
          <a-radio-group v-model="condition.LX" name="radioGroup" @change="timeliness">
            <a-radio :value="1"> 实时数据 </a-radio>
            <a-radio :value="2"> 历史数据 </a-radio>
          </a-radio-group>
          <div v-show="condition.LX == 2">
            <a-range-picker
              :default-value="[moment($store.state.KSSJ), moment($store.state.JSSJ)]"
              show-time
              :size="size"
              :format="dateFormat"
              @change="onChange"
            />
            <a-button
              style="margin-left: 20px"
              :size="size"
              type="primary"
              @click="onSubmit"
            >
              确定
            </a-button>
          </div>
        </a-space>
      </div>
      <div class="search-criteria">
        <a-space style="margin-top: 10px" size="small">
          <div class="police-title">报警类型:</div>
          <a-radio-group
            v-model="condition.BJLX"
            @change="onChangeType"
            button-style="solid"
          >
            <a-radio-button :value="1"> 异常 </a-radio-button>
            <a-radio-button :value="2"> 超标 </a-radio-button>
            <a-radio-button :value="3"> 预警 </a-radio-button>
          </a-radio-group>
        </a-space>
      </div>

      <div class="list-wrapper">
        <!-- 异常：#884898
                    超标：#E22222
                    预警：#F67C49 -->
        <a-icon
          v-show="loading"
          type="loading"
          style="font-size: 26px; display: block; color: rgb(24, 118, 255)"
        />
        <div v-show="!loading">
          <div v-if="data.length > 0">
            <div class="list-item" v-for="(item, index) in data" :key="index">
              <div class="left-border" :style="getLeftColor(item)"></div>
              <div class="right-content">
                <div class="top">
                  <div class="text-area">
                    <p>
                      <span v-if="item.EnterpriseMC">{{ item.EnterpriseMC }} -</span>
                      <span>{{ item.station_name }}</span>
                    </p>
                    <p>{{ item.AlarmMsg }}</p>
                    <div v-if="item.LX == 2 && item.GBSJ">
                      <p v-if="item.gdz != 1" style="font-size: 16px">
                        状态改变为{{ isGBLX(item.GBLX) }}，监测值：{{ item.GBZ
                        }}{{ item.UNIT }}
                      </p>
                      <p v-else style="font-size: 16px">
                        在{{ item.GBSJ }}，固定值报警结束，监测值：{{ item.GBZ
                        }}{{ item.UNIT }}
                      </p>
                      <div v-if="item.SFCL == 1">
                        <p style="font-size: 16px">处理说明: {{ item.Explain }}</p>
                        <p style="font-size: 16px">处理时间: {{ item.CLSJ }}</p>
                        <p style="font-size: 16px">处理人: {{ item.CLRY }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="img-area">
                    <img :src="getImgSrc(item)" />
                  </div>
                </div>
                <div class="deal-con" style="display:flex;">
                    <div class="bottom">报警时间：{{ item.BJSJ }}</div>
                    <div class="deal" v-if="LX!=1&&item.SFCL==0" @click="onDeal(item)">立即处理</div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-else
            style="
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            <a-empty />
          </div>
        </div>
      </div>

      <a-pagination
        class="pagination-wrapper"
        :default-current="start"
        :total="alltotal"
        :show-total="
          (total, range) => `显示 ${range[0]}-${range[1]} 条，共 ${alltotal} 条`
        "
        :defaultPageSize="limit"
        @change="onPageChange"
      />
    </div>
    <a-modal
    title="报警处理"
    :visible="alarmVisible"
    :confirm-loading="confirmLoading"
    @ok="handleAlarmOk"
    @cancel="handleAlarmCancel"
    centered
    class="myalarmmodel"
    :width="400"
    :zIndex=999
    >
    <div v-if="alarminfo.BJLX==1">报警类型：异常</div>
    <div v-if="alarminfo.BJLX==2">报警类型：超标</div>
    <div v-if="alarminfo.BJLX==3">报警类型：预警</div>
    <div class="dealinfo" style="width:352px;">
      <div style="width:71px;">报警信息：</div>
      <div style="flex:1;">{{alarminfo.AlarmMsg}}</div>
    </div>
    <div>报警时间：{{alarminfo.BJSJ}}</div>
    <a-form-model
      ref="ruleForm"
      :rules="rules"
      :model="form"
    >
   <a-form-model-item label="处理说明" prop="Explain">
      <a-input style="height:150px;width:273px;" v-model="form.Explain" type="textarea" />
    </a-form-model-item>
    </a-form-model>    
    </a-modal>
  </div>
</template>

<script>
import AppTree from "@/components/AppTree.vue";
import { http } from "../../utils/Http.js";
import moment from "moment";
const alarminfo={
          CLRY:'',
          BJSJ:'',
          Explain:'',
          AlarmMsg:'',
          BJLX:'',
          SFCL:0
      };
export default {
  data() {
    return {
      layout:'horizontal',
      BJLX:'',
      form: {
       Explain:''
      },
      rules:{
        Explain:[
          {required: true, message: '请输入处理说明',trigger: 'change'}
        ]
      },
      alarminfo:Object.assign({},alarminfo),
      alarmVisible:false,//处理说明对话框
      confirmLoading:false,//处理说明确认正在加载
      size: "default",
      dateFormat: "YYYY-MM-DD",
      condition: {
        KSSJ: this.$store.state.KSSJ, // 开始时间
        JSSJ: this.$store.state.JSSJ, // 结束时间
        LX: 1, // 查询类型
        BJLX: 1, // 报警类型
      },
      // list 相关
      columns: [],
      data: [],
      alltotal: 0, // 总数据的length
      start: 1, // table 页面，从第几页开始。
      limit: 20, // 一页多少条。

      // 请求接口 loading 相关。
      loading: true,
      LX:''
    };
  },

  components: {
    AppTree,
  },

  watch: {},

  computed: {
    /**
     * 获取左边的颜色
     */
    getLeftColor() {
      return (values) => {
        if (values.SFCL == "0") {
          //未处理
          if (values.BJLX == "1") {
            //异常
            return "background-color: #884898;";
          } else if (values.BJLX == "2") {
            //超标
            return "background-color: #e22222;";
          } else if (values.BJLX == "3") {
            //预警
            return "background-color: #f67c49;";
          }
        } else if (values.SFCL == "1") {
          //已处理
          if (values.BJLX == "1") {
            //异常
            return "background-color: #E7DAEA;";
          } else if (values.BJLX == "2") {
            //超标
            return "background-color: #FDDFDF;";
          } else if (values.BJLX == "3") {
            //预警
            return "background-color: #FDE5DB;";
          }
        }
      };
    },

    /**
     * 获取图片的src
     */
    getImgSrc() {
      return (values) => {
        // console.log(values)
        if (values.SFCL == "0") {
          //未处理
          if (values.LX == 1) {
            //实时状态
            return "/pics/010.png";
          } else {
            //历史数据
            return "/pics/017.png";
          }
        } else if (values.SFCL == "1") {
          //已处理
          return "/pics/011.png";
        }
      };
    },
    isGBLX() {
      return (GBLX) => {
        if (GBLX == "0") {
          return "正常";
        } else if (GBLX == "1") {
          return "异常";
        } else if (GBLX == "2") {
          return "超标";
        } else if (GBLX == "3") {
          return "预警";
        } else if (GBLX == "4") {
          return "异常解除";
        }
      };
    },
  },

  created() {
    var me = this;
  },

  mounted() {
    // this.onSearch();
    this.LX=this.$store.state.loggedIn.LX
  },

  methods: {
    moment,

    /**
     * 左侧树的选择事件。
     */
    onSelect(item) {
      // // console.log(item);
      this.onSearch();
    },
    //立即处理
    onDeal(item){
      var me=this;
      var AlarmID=item.AlarmID;
      me.AlarmID=AlarmID;
      var BJLX=item.BJLX;
      this.alarminfo=item;
      if(Number(BJLX)==1||Number(BJLX)==2){
        this.alarmVisible=true;
      }
      if(Number(BJLX)==3){
        this.confirmDeal();
      }
    },
    //confirmDeal
    confirmDeal(){
      var me=this;
      this.$confirm({
      title: '报警处理',
      content: '是否确认当前预警',
      okText: '确认',
      cancelText: '取消',
      centered:true,
      onOk(){
        http({
        url: "/data/HJJC_ALARM_HANDLE.aspx",
        data: {
            CKM: me.$store.state.loggedIn.CKM,
            AlarmID: me.AlarmID,
            Explain:'已确认'
        },
        success(obj) {
          me.onSearch();
        },
          failure(obj) {
          },
          me,
        });
      }
    });
    },
    //处理报警信息确认
    handleAlarmOk(e){
      var me=this;
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          me.confirmLoading=true;
          http({
          url: "/data/HJJC_ALARM_HANDLE.aspx",
          data: {
              CKM: me.$store.state.loggedIn.CKM,
              AlarmID: me.AlarmID,
              Explain:me.form.Explain
          },
          success(obj) {
            me.alarmVisible=false;
            me.confirmLoading=false;
            me.AlarmID='';
            me.alarminfo=Object.assign({},alarminfo);
            me.onSearch();
          },
            failure(obj) {
              me.confirmLoading=false;
            },
            me,
          });
        } else {
          return false;
        }
      });
    },
    //处理报警信息取消
    handleAlarmCancel(){
      var me=this;
      me.alarmVisible=false;
    },
    /**
     * 选择日期
     */
    onChange(date, dateString) {
      console.log(dateString);
      this.condition.KSSJ = dateString[0];
      this.condition.JSSJ = dateString[1];
    },
    /**
     * 报警类型
     */
    onChangeType(e) {
      this.onSearch();
    },
    /**
     * 确定
     */
    onSubmit() {
      let me = this;
      me.start = 1;
      this.onSearch();
    },
    /**
     * 时效性的切换事件。
     */
    timeliness() {
      let me = this;
      me.onSearch();
    },
    /**
     * 获取数据信息
     */
    onSearch() {
      var me = this;

      let item = me.$store.state.selectedData;
      console.log(item);
      console.log(this.condition);
      var Lx = item.Lx;
      var BH = item.id;
      let area_num = "";
      switch (Lx) {
        case "1":
          area_num = "";
          break;
        case "2":
          area_num = BH;
          break;
        case "3":
          area_num = BH;
          break;
        case "4":
          area_num = BH;
          break;
      }

      var params = {};
      // 5是企业，非企业的（区域）就有传递 地区编号
      if (Lx == "5") {
        params = {
          CKM: this.$store.state.loggedIn.CKM,
          LX: this.condition.LX,
          station_bh: "",
          KSSJ: this.condition.KSSJ,
          JSSJ: this.condition.JSSJ,
          start: (me.start - 1) * me.limit, // table 页面，从第几页开始。
          limit: me.limit, // 一页多少条。
          BJLX: this.condition.BJLX,
          enterprise_bh: this.$store.state.selectedKeys[0],
          area_num: "",
        };
      } else if (Lx == "6") {
        params = {
          CKM: this.$store.state.loggedIn.CKM,
          LX: this.condition.LX,
          station_bh: this.$store.state.selectedKeys[0],
          KSSJ: this.condition.KSSJ,
          JSSJ: this.condition.JSSJ,
          start: (me.start - 1) * me.limit, // table 页面，从第几页开始。
          limit: me.limit, // 一页多少条。
          BJLX: this.condition.BJLX,
          enterprise_bh: "",
          area_num: "",
        };
      } else {
        params = {
          CKM: this.$store.state.loggedIn.CKM,
          LX: this.condition.LX,
          station_bh: "",
          KSSJ: this.condition.KSSJ,
          JSSJ: this.condition.JSSJ,
          start: (me.start - 1) * me.limit, // table 页面，从第几页开始。
          limit: me.limit, // 一页多少条。
          BJLX: this.condition.BJLX,
          enterprise_bh: "",
          // area_num: this.$store.state.selectedKeys[0],
          area_num: area_num,
        };
      }

      console.log(params);

      me.getAjax(params);
    },

    /**
     * 请求列接口。
     */
    getAjax(params) {
      let me = this;
      me.loading = true;
      http({
        url: "/data/q30/HJJC_ALARM_RECORD_STATE_V2.aspx",
        data: params,
        success(obj) {
          console.log("table 的数据：", obj);
          me.loading = false;
          me.data = obj.Data;
          me.alltotal = obj.Data1[0].ROWSCOUNT;
        },
        failure(obj) {
          me.loading = false;
        },
        me,
      });
    },

    Chart() {
      // 基于准备好的dom，初始化echarts实例
      var myChart = this.$echarts.init(document.getElementById("Echarts"));
      var option = null;
      option = {
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: [820, 932, 901, 934, 1290, 1330, 1320],
            type: "line",
            areaStyle: {},
          },
        ],
      };
      if (option && typeof option === "object") {
        myChart.setOption(option, true);
      }
    },
    /**
     * 分页切换点击事件
     */
    onPageChange(page, pageSize) {
      let me = this;
      me.start = page || 1;
      me.limit = pageSize;
      // console.log(page, pageSize)
      me.onSearch();
    },
  },
};
</script>
<style lang="scss">
.myalarmmodel{
  .ant-modal-title{
    text-align: center;
  }
  .ant-modal-body{
    padding-bottom: 0;
  }
  .dealinfo{
    display: flex;
  }
  .ant-form-item{
    display: flex;
    margin-top:5px;
  }
  .ant-form-item-label{
    line-height: 0;
  }
  .ant-form-item-label > label{
    font-size: 12px;
    color:rgba(0, 0, 0, 0.65) !important;
  }
}
</style>
<style lang="scss" scoped>
#alarm {
  width: 1200px;

  margin: 0 auto;
  display: flex;
  padding-top: 30px;
  height: 100%;

  .tree::-webkit-scrollbar {
    width: 5px;
    background-color: #d9d9d9;
  }

  .tree::-webkit-scrollbar-thumb {
    height: 30px;
    background-color: #52c41a;
  }

  .content {
    flex: 1;
    background-color: #ffffff;
    padding-top: 20px;

    .title {
      text-align: center;
      font-size: 24px;
    }

    .search-criteria {
      // display: flex;
      padding: 0 50px;
      height: 33px;
      display: flex;
      align-content: center;

      .large-title {
        margin-right: 10px;
      }

      .ant-radio-wrapper {
        margin-right: 14px;

        // span.ant-radio{
        // }
      }

      .police-title {
        margin-right: 40px;
      }
    }
  }

  #Echarts {
    height: 300px;
    width: 500px;
  }

  .list-wrapper::-webkit-scrollbar {
    display: none;
  }

  .list-wrapper {
    overflow: hidden;
    // height: calc(100% - 160px);
    padding: 0px 20px;
    overflow-y: auto;
    min-height: 792px;

    .list-item {
      border-radius: 8px;
      box-shadow: 0px 0px 1px 0px rgba(136, 136, 136, 0.3);
      border: solid 1px #dedfe0;
      margin-top: 20px !important;
      position: relative;
      display: flex;

      .left-border {
        width: 10px;
        border-radius: 8px 0px 0px 8px;
      }

      .right-content {
        flex: 1;
        padding-left: 30px;

        .top {
          display: flex;

          .text-area {
            flex: 1;

            p {
              font-size: 16px;
              margin: 16px 0px;
            }
          }

          .img-area {
            margin: 20px 20px 11px 0;
            height: 89px;
            width: 89px;

            img {
              width: 89px;
              height: 89px;
            }
          }
        }

        .bottom {
          border-top: solid 1px #dedfe0;
          height: 48px;
          line-height: 48px;
          font-family: PingFang-SC-Medium;
          font-size: 20px;
          color: #888;
        }
      }
    }
  }

  // table的分页区域
  .pagination-wrapper {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
  }
  .deal-con{
    display: flex;
    height: 48px;
    align-items: center;
    justify-content: space-between;
    .deal{
      width:80px;
      height: 32px;
      text-align: center;
      line-height: 32px;
      background: #1890ff;
      color:#fff;
      border-radius: 4px;
      margin-right: 20px;
      cursor: pointer;
    }
  }
}
</style>
